import { useNavigate } from "react-router-dom";
import MUIDataTable, {
  FilterType,
  Responsive,
  SelectableRows,
} from "mui-datatables";
import { AlertColor, Box, createTheme, ThemeProvider } from "@mui/material";
import ButtonWithDialog from "../DialogButton";
import "./index.css";
import config, { ApiCompanyData, getConfigForRegion, Regions } from "../../config";
import { SyntheticEvent, useEffect, useState } from "react";
import React from "react";
import FrontEndContext from "../../context/FrontEndContext";
import CustomSnackbar from "../CustomSnackbar";
import axios from "axios";
import CustomButton from "../CustomButton";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

const { ID_CREATE_NEW } = config.app;

interface CompaniesListProps {
  companiesValid: boolean;
  companies?: ApiCompanyData[];
}

export default function CompaniesList(props: CompaniesListProps) {
  const { companiesValid, companies } = props;
  const navigate = useNavigate();
  const data = (companiesValid && companies) || [];

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success");
  const [isLoading, setIsLoading] = useState(false);

  const {
    isAuthenticated,
    isAuthenticating,
    refreshCompanies,
    headersWithAuth,
  } = React.useContext(FrontEndContext);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const options = {
    setCellProps: () => ({
      align: "left",
    }),
  };

  const buttonViewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any, tableMeta: { rowData: any[] }) => {
      // Get region from rowData - index 3 matches the Region column position
      const region = tableMeta.rowData[3];
      return (
        <CustomButton
          variant="contained"
          onClick={() => navigate(`/company/${value}?region=${encodeURIComponent(region)}`)}
          icon={EditRoundedIcon}
          iconColor="#1b76d2"
        />
      );
    },
  };

  const handleDelete = async (id: number, region: string) => {
    try {
      setIsLoading(true);
      setOpenSnackbar(true);
      console.log(`Deleting company: ${id} from region ${region} ...`);
      setSnackbarMessage(`Started Deleting the company: ${id} ...`);
      setSnackbarSeverity("success");

      // Find the matching region configuration
      const selectedRegion = Object.values(Regions).find(
        (r) => r.displayName === region
      );
      if (!selectedRegion) throw new Error("Invalid region");

      const regionConfig = getConfigForRegion(selectedRegion);
      
      const response = await axios.post(
        regionConfig.api.DELETE_COMPANY,
        { id: id },
        headersWithAuth
      );

      if (response.status === 200) {
        setSnackbarMessage("Company deleted successfully!");
        setSnackbarSeverity("success");
        refreshCompanies();
      } else if (response.status === 400) {
        console.log(response);
        console.log(`Deleted ${regionConfig.api.API_FAIL_ERROR}${response.status}`);
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
      } else {
        console.log(`Deleted ${regionConfig.api.API_FAIL_ERROR}${response.status}`);
        setSnackbarMessage("Failed to delete company.");
        setSnackbarSeverity("error");
      }
    } catch (error: any) {
      console.log(error.response?.data?.message);
      if (
        error.response?.data?.message ===
        "Cannot delete company as it either has users or systems."
      ) {
        setSnackbarMessage(`Error: ${error.response.data.message}`);
        setSnackbarSeverity("error");
      } else {
        setSnackbarMessage(
          `Error: ${error.message || "Failed to delete company"}`
        );
        setSnackbarSeverity("error");
      }
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };

  const deleteButton = {
    filter: false,
    viewColumns: false,
    customBodyRender: (companyId: number, tableMeta: { rowData: any[] }) => {
      // Get region from rowData - index 3 matches the Region column position
      const region = tableMeta.rowData[3];
      const companyToDelete = data.find(
        (company) => company.id === companyId && company.region === region
      );

      if (!companyToDelete) {
        return null;
      }

      return (
        <ButtonWithDialog
          onDelete={() => handleDelete(companyToDelete.id, region)}
          typeOfOperation="Delete"
        />
      );
    },
  };

  const newCompanyTitle = (
    <CustomButton
      variant="primary"
      data-cy="createCompany"
      onClick={() => navigate(`/company/${ID_CREATE_NEW}`)}
      text="Create Company"
    />
  );

  const COLUMNS = [
    { label: " ", name: "id", options: buttonViewOptions },
    { label: " ", name: "id", options: deleteButton },
    { label: "Name", name: "name", options },
    { label: "Region", name: "region", options },
    { label: "Address 1", name: "address1", options },
    { label: "Address 2", name: "address2", options },
    { label: "City", name: "city", options },
  ];

  const OPTIONS = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "vertical" as Responsive,
    search: true,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: "companies",
    textLabels: {
      body: {
        noMatch: companiesValid ? "No companies available." : "Loading ...",
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "left",
            },
          },
        },
      },
    });

  return (
    <Box className="companies-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={newCompanyTitle}
          data={data}
          columns={COLUMNS}
          options={OPTIONS}
        />
      </ThemeProvider>

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
}
import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Box } from "@mui/material";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import LoginButton from "../../components/LoginButton";
import "./index.css";
import { SERVER_ENV } from "../../config";
import QRCode from "react-qr-code";

const HEADER_NAME = "";
const IS_TEST_ENV = process.env.REACT_APP_ENV === 'test';


type LoginState = "LOGIN" | "MFA_SETUP" | "MFA_CHALLENGE";

export default function Login() {
  const navigate = useNavigate();
  const { handleAuthentication } = useContext(FrontEndContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loginState, setLoginState] = useState<LoginState>("LOGIN");
  const [cognitoUser, setCognitoUser] = useState<any>(null);
  const [totp, setTotp] = useState<string>("");

  const [mfaCode, setMfaCode] = useState<string>("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleLogin(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const user = await Auth.signIn(email, password);
      setCognitoUser(user);

      if (IS_TEST_ENV) {
        // Bypass MFA for test environment
        handleAuthentication(user.signInUserSession);
        navigate("/");
      } 
      else{
        const challenge: string | undefined = user.challengeName;
        if (challenge == null) {
          throw "MFA not enforced for user, this shouldn't happen."
        }
        if (challenge === "MFA_SETUP") {
          const totp = await Auth.setupTOTP(user);
          setTotp(totp);
          setLoginState(challenge);
        } else if (challenge === "SOFTWARE_TOKEN_MFA") {
          setLoginState("MFA_CHALLENGE");
        } else {
          throw `Got an invalid challenge: ${challenge}`
        }
      }
    } catch (e) {
      setErrorMessage('Invalid username or password. Please try again.');
      handleAuthentication(null, e);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMfa(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.confirmSignIn(cognitoUser, mfaCode, 'SOFTWARE_TOKEN_MFA');
      handleAuthentication(cognitoUser.getSignInUserSession());
      navigate("/");
    } catch (e) {
      setErrorMessage('Invalid username or password. Please try again.');
      handleAuthentication(null, e);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleMfaSetup(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.verifyTotpToken(cognitoUser, mfaCode);
      handleAuthentication(cognitoUser.getSignInUserSession());
      alert("Your MFA has been setup!");
      navigate("/");
    } catch (e) {
      console.log(e);
      setErrorMessage('Invalid username or password. Please try again.');
      handleAuthentication(null, e);
    } finally {
      setIsLoading(false);
    }
  }

  const htr = (eventHandler: (input: string)=>any) => {
    return (e: React.ChangeEvent<{value: string}>) => {
      const value = e.target.value;
      eventHandler(value);
    };
  }

  const issuer=`BSSI-PP-AdminConsole-${SERVER_ENV}`

  return (
    <Page name={HEADER_NAME} hideAuthentication={true}>
      <Box className="login">
        {loginState === "LOGIN" && (
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Username</Form.Label>
              <Form.Control data-cy="username-input" autoFocus type="text" value={email} onChange={htr(setEmail)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control data-cy="password-input" type="password" value={password} onChange={htr(setPassword)} />
            </Form.Group>
            <LoginButton size="lg" type="submit" data-cy="login-button" isLoading={isLoading} disable={!validateForm()}>
              Login
            </LoginButton>
          </Form>
        )}
        {loginState === "MFA_SETUP" && (
          <Form onSubmit={handleMfaSetup}>
            <Form.Group className="mb-3" controlId="2fa-image">
              <p className="fs-3 mb-4 text-center">Please Setup 2FA</p>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`otpauth://totp/${issuer}:${email}?secret=${totp}&issuer=${issuer}&algorithm=SHA1&digits=6&period=30`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="2fa-code">
              <Form.Label>Code</Form.Label>
              <Form.Control data-cy="2fa-input" autoFocus type="text" value={mfaCode} onChange={htr(setMfaCode)} />
            </Form.Group>
            <LoginButton size="lg" type="submit" data-cy="login-button" isLoading={isLoading} disable={!validateForm()}>
            Submit
            </LoginButton>
          </Form>
        )}
        {loginState === "MFA_CHALLENGE" && (
          <Form onSubmit={handleMfa}>
            <Form.Group className="mb-3" controlId="2fa-image">
              <p className="fs-3 mb-4 text-center">Please Enter your 2FA Code</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="2fa-code">
              <Form.Label>Code</Form.Label>
              <Form.Control data-cy="2fa-input" autoFocus type="text" value={mfaCode} onChange={htr(setMfaCode)} />
            </Form.Group>
            <LoginButton size="lg" type="submit" data-cy="login-button" isLoading={isLoading} disable={!validateForm()}>
            Submit
            </LoginButton>
          </Form>
        )}
        <div data-cy="error-message" className="error-message">
          {errorMessage && <p>{errorMessage}</p>}
        </div>
      </Box>
    </Page>
  );
}

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth } from "aws-amplify";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import FrontEndContext, {
  FrontEndContextInterface,
} from "./context/FrontEndContext";
import Home from "./pages/Home";
import User from "./pages/User";
import Company from "./pages/Company";
import System from "./pages/System";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Login from "./pages/Login";

import config, {
  ApiUserData,
  ApiSystemData,
  ApiCompanyData,
  ApiErrorLogData,
  PresignedUrlResponse,
  ApiErrorLogDataResponse,
  ApiLambdaErrorLog,
  Regions,
  getConfigForRegion,
} from "./config";

const { USERS, COMPANIES, SYSTEMS, SYSTEM_CERTS, API_FAIL_ERROR, ERRORLOGS } =
  config?.api;

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bearerToken, setBearerToken] = useState("");
  const [usersValid, setUsersValid] = useState(false);
  const [users, setUsers] = useState<ApiUserData[]>([]);
  const [companiesValid, setCompaniesValid] = useState(false);
  const [companies, setCompanies] = useState<ApiCompanyData[]>([]);
  const [systemsValid, setSystemsValid] = useState(false);
  const [systems, setSystems] = useState<ApiSystemData[]>([]);
  const [preSigned, setPreSigned] = useState<PresignedUrlResponse[]>([]);
  const [errorLogsValid, setErrorLogsValid] = useState(false);
  const [errorLogs, setErrorLogs] = useState<ApiErrorLogData[]>([]);
  const [errorLambdaLogs] = useState<ApiLambdaErrorLog[]>([]);



  const navigate = useNavigate();

  const headersWithAuth: any = useMemo(() => {
    if (!bearerToken) {
      return undefined;
    }
    return { headers: { Authorization: `Bearer ${bearerToken}` } };
  }, [bearerToken]);

  const resetApp = () => {
    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setBearerToken("");
  };

  const handleAuthentication = useCallback((userSession: any, error?: any) => {
  if (userSession) {
    setIsAuthenticated(true);
    setBearerToken(userSession.getIdToken().getJwtToken());
  } else {
    // When not logged in, reset the app
    resetApp();

    if (error && error !== "No current user") {
      
      // For test environment, don't show the alert
      if (process.env.REACT_APP_ENV !== 'test') {
        setBearerToken("");
        const errorMsg = `Authentication error: ${(error as any)?.message || "unknown error"}`;
        console.log(errorMsg);
        alert(errorMsg);
      }
    }
  }

  setIsAuthenticating(false);
}, []);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const currentSession = await Auth.currentSession();
        handleAuthentication(currentSession);
      } catch (e) {
        handleAuthentication(null, e);
      }
    };

    authenticateUser();
  }, [handleAuthentication]);

  // retrieve the list of users
  const getUsersData = useCallback(async () => {
  try {
    const usersPromises = Object.values(Regions).map(async region => {
      const regionConfig = getConfigForRegion(region);
      try {
        const response = await axios.post<ApiUserData[]>(
          regionConfig.api.USERS,
          {},
          headersWithAuth
        );

        if (response?.status >= 400) {
          console.log(`[Users ${region.displayName}] ${API_FAIL_ERROR}${response?.status}`);
          return [];
        }

        return response.data.map(user => ({
          ...user,
          region: region.displayName
        }));
      } catch (error) {
        console.log(`[Users ${region.displayName}] ${API_FAIL_ERROR}${error}`);
        return [];
      }
    });

    const results = await Promise.all(usersPromises);
    const allUsers = results.flat();

    if (allUsers.length > 0) {
      setUsers(allUsers);
    } else {
      console.log("[Users] No users returned from any region.");
    }
  } catch (error) {
    console.log(`[Users] ${API_FAIL_ERROR}${error}`);
  }
}, [headersWithAuth]);

  
   const getCompaniesData = useCallback(async () => {
    try {

    const companiesPromises = Object.values(Regions).map(async region => {
      const regionConfig = getConfigForRegion(region);

      try {
        const response = await axios.post<ApiCompanyData[]>(
          regionConfig.api.COMPANIES,
          {},
          headersWithAuth
        );

        if (response?.status >= 400) {
          console.log(`[Companies ${region.displayName}] ${API_FAIL_ERROR}${response?.status}`);
          return [];
        }
        return response.data.map(company => ({
          ...company,
          region: region.displayName
        }));
      } catch (error) {
        console.log(`[Companies ${region.displayName}] ${API_FAIL_ERROR}${error}`);
        return [];
      }
    });

    // Wait for all region requests to complete
    const results = await Promise.all(companiesPromises);
    
    // Combine all companies from all regions
    const allCompanies = results.flat();

    if (allCompanies.length > 0) {
      setCompanies(allCompanies);
    } else {
      console.log("[Companies] No companies returned from any region.");
    }
  } catch (error) {
    console.log(`[Companies] ${API_FAIL_ERROR}${error}`);
  }
}, [headersWithAuth]);


  const getSystemsData = useCallback(async () => {
  try {
    const systemsPromises = Object.values(Regions).map(async region => {
      const regionConfig = getConfigForRegion(region);
      try {
        const response = await axios.post<ApiSystemData[]>(
          regionConfig.api.SYSTEMS,
          {},
          headersWithAuth
        );

        if (response?.status >= 400) {
          console.log(`[Systems ${region.displayName}] ${API_FAIL_ERROR}${response?.status}`);
          return [];
        }

        return response.data.map(system => ({
          ...system,
          region: region.displayName
        }));
      } catch (error) {
        console.log(`[Systems ${region.displayName}] ${API_FAIL_ERROR}${error}`);
        return [];
      }
    });

    const results = await Promise.all(systemsPromises);
    const allSystems = results.flat();

    if (allSystems.length > 0) {
      setSystems(allSystems);
    } else {
      console.log("[Systems] No systems returned from any region.");
    }
  } catch (error) {
    console.log(`[Systems] ${API_FAIL_ERROR}${error}`);
  }
}, [headersWithAuth]);

  const getPreSignedData = useCallback(async () => {
  try {
    const presignedPromises = Object.values(Regions).map(async region => {
      const regionConfig = getConfigForRegion(region);
      try {
        const response = await axios.post<PresignedUrlResponse[]>(
          regionConfig.api.SYSTEM_CERTS,
          {},
          headersWithAuth
        );

        if (response?.status >= 400) {
          console.log(`[PreSigned ${region.displayName}] ${API_FAIL_ERROR}${response?.status}`);
          return [];
        }

        return response.data;
      } catch (error) {
        console.log(`[PreSigned ${region.displayName}] ${API_FAIL_ERROR}${error}`);
        return [];
      }
    });

    const results = await Promise.all(presignedPromises);
    const allPresigned = results.flat();

    if (allPresigned.length > 0) {
      setPreSigned(allPresigned);
    } else {
      console.log("[PreSigned] No PreSigned returned from any region.");
    }
  } catch (error) {
    console.log(`[PreSigned] ${API_FAIL_ERROR}${error}`);
  }
}, [headersWithAuth]);


  const getErrorLog = useCallback(async () => {
  try {
    const errorLogsPromises = Object.values(Regions).map(async region => {
      const regionConfig = getConfigForRegion(region);
      try {
        const response = await axios.get<ApiErrorLogDataResponse>(
          regionConfig.api.ERRORLOGS,
          headersWithAuth
        );

        if (response?.status >= 400) {
          console.log(`[Error Log Response ${region.displayName}] ${API_FAIL_ERROR}${response?.status}`);
          return [];
        }

        return response.data.data;
      } catch (error) {
        console.log(`[Error Log Response ${region.displayName}] ${API_FAIL_ERROR}${error}`);
        return [];
      }
    });

    const results = await Promise.all(errorLogsPromises);
    const allErrorLogs = results.flat();

    if (allErrorLogs.length > 0) {
      setErrorLogsValid(true);
      setErrorLogs(allErrorLogs);
    } else {
      console.log("[Error Log Response] No logs returned from any region.");
    }
  } catch (error) {
    console.log(`[Error Log Response] ${API_FAIL_ERROR}${error}`);
  }
}, [headersWithAuth]);



  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    if (usersValid) {
      return;
    }

    setUsersValid(true);
    getUsersData();
  }, [headersWithAuth, isAuthenticated, usersValid, getUsersData]);

  // retrieve the list of companies
  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    if (companiesValid) {
      return;
    }
    setCompaniesValid(true);
    getCompaniesData();
  }, [headersWithAuth, isAuthenticated, companiesValid, getCompaniesData]);

  // retrieve the list of systems
  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    if (systemsValid) {
      return;
    }

    setSystemsValid(true);

    getSystemsData();
    getPreSignedData();
    getErrorLog();
  }, [headersWithAuth, isAuthenticated, systemsValid, getSystemsData, getPreSignedData, getErrorLog]);

  // retrieve the lambda logs
  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

  }, [headersWithAuth, isAuthenticated, companiesValid]);

  async function doLogin() {
    navigate("/login");
  }

  async function doLogout() {
    await Auth.signOut();
    resetApp();
    navigate("/login");
  }

  const queryClient = new QueryClient();

  const frontEndContext: FrontEndContextInterface = {
    handleAuthentication,
    headersWithAuth,
    isAuthenticating,
    isAuthenticated,
    doLogin,
    doLogout,
    companiesValid,
    companies,
    usersValid,
    users,
    systemsValid,
    systems,
    preSigned,
    errorLogsValid,
    errorLogs,
    errorLambdaLogs,
    refreshUsers: getUsersData,
    refreshSystems: getSystemsData,
    refreshCompanies: getCompaniesData,
  };

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FrontEndContext.Provider value={frontEndContext}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/user/:userId" element={<User />} />
            <Route path="/company/:companyId" element={<Company />} />
            <Route path="/system/:systemId" element={<System />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/lost" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </FrontEndContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;

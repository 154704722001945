const SERVER_SCHEME = "https";
export const SERVER_ENV = process.env.SERVER_ENV || "dev";

export const Regions: Record<string, Region> = {
  Canada: {
    id: 'canada',
    displayName: 'Canada',
    apiUrl: process.env.REACT_APP_API || `admin-api.planter.dev.bluefieldtest.ca`,
  },
  Europe: {
    id: 'europe',
    displayName: 'Europe',
    apiUrl: process.env.REACT_APP_API_EUROPE || 'admin-api.planter.dev.eu.bluefieldtest.ca',
  },
} as const;



const getServerUrl = (region: Region) => {
  return `${SERVER_SCHEME}://${region.apiUrl}`;
};

const createApiConfig = (region: Region) => {
  const SERVER_URL = getServerUrl(region);
  const USER_ROOT = `${SERVER_URL}/web_user`;
  const SYSTEM_ROOT = `${SERVER_URL}/system`;
  const COMPANY_ROOT = `${SERVER_URL}/company`;
  const OPERATIONHISTORY_ROOT = `${SERVER_URL}/logs`;

  return {
    API_FAIL_ERROR: "Could not fetch data from server",
    USERS: `${USER_ROOT}/web_users`,
    CREATE_USERS: `${USER_ROOT}/create`,
    DELETE_USER: `${USER_ROOT}/delete`,
    UPDATE_USER: `${USER_ROOT}/update`,
    UPDATE_USER_PASSWORD: `${USER_ROOT}/update_password`,
    UPDATE_COMPANY: `${COMPANY_ROOT}/update`,
    SYSTEMS: `${SYSTEM_ROOT}/systems`,
    CREATE_SYSTEMS: `${SYSTEM_ROOT}/create`,
    UPDATE_SYSTEMS: `${SYSTEM_ROOT}/update`,
    DELETE_SYSTEMS: `${SYSTEM_ROOT}/delete`,
    SYSTEM_EXTRA_DETAILS: `${SYSTEM_ROOT}/extra_details`,
    OPERATIONHISTORIES: `${OPERATIONHISTORY_ROOT}/admin_logs`,
    COMPANIES: `${COMPANY_ROOT}/companies`,
    COMPANY: `${COMPANY_ROOT}/company`,
    CREATE_COMPANY: `${COMPANY_ROOT}/create`,
    DELETE_COMPANY: `${COMPANY_ROOT}/delete`,
    SYSTEM_CERTS: `${SYSTEM_ROOT}/certs`,
    SYSTEM_REGENERATE_CERTS: `${SYSTEM_ROOT}/recreate_cert`,
    ERRORLOGS: `${SERVER_URL}/logs/lambda/status`,
    ERRORLOGS_LAMBDA: `${SERVER_URL}/logs/lambda`,
  };
};

const getRegionConfig = (region: Region) => ({
  s3: {
    REGION: region.id === 'europe' ? "eu-west-1" : "ca-central-1",
    BUCKET: `config-bucket-325591102945-${region.id === 'europe' ? "eu-west-1" : "ca-central-1"}`,
  },
  apiGateway: {
    REGION: region.id === 'europe' ? "eu-west-1" : "ca-central-1",
    URL: getServerUrl(region),
  },
  cognito: {
    REGION: region.id === 'europe' ? "eu-west-1" : "ca-central-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: "None",
  },
});

const config = {
  ...getRegionConfig(Regions.Canada),
  api: createApiConfig(Regions.Canada),
  app: {
    ID_CREATE_NEW: "create",
  },
};

export const getConfigForRegion = (region: Region) => ({
  ...getRegionConfig(region),
  api: createApiConfig(region),
  app: config.app,
});

export interface Region {
  id: string;
  displayName: string;
  apiUrl: string;
}


export interface ApiCompanyData {
  id: number;
  name: string;
  address1?: string;
  address2?: string;
  city: string;
  region: string;
}

export interface ApiOperationHistory {
  userName: string;
  entityType: string;
  operation: string;
  operationValues: string;
  timestamp: string;
}

export interface ApiUserData {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  companyId: number;
  companyName: string;
  lastLoginTs: string;
}

export interface ApiSystemData {
  id: string;
  name: string;
  companyId: number;
  companyName: string;
  status: string;
  updateTs: string;
}

export interface ApiCompanyDataExt extends ApiCompanyData {
  webUsers: ApiUserData[];
  systems: ApiSystemData[];
}

export interface PresignedUrlResponse {
  success: boolean;
  error: string | null;
  url: string;
}


export interface LogEntry {
  eventId: string;
  ingestionTime: number;
  logStreamName: string;
  message: string;
  timestamp: number;
}

export interface ApiErrorLogData {
  lambdaName: string;
  logs: LogEntry[];
}

export interface ApiSystemExtraDetails {
  id: string;
  nodeVersion: string;
  osVersion: string;
  pusherVersion: string;
  apiVersion: string;
  schemaVersion: string;
  applicationVersion: string;
  numberOfWheels: number;
  liveViewUpdateTs: string;
  liveViewKeepAliveTs: string;
  activePlantingUpdateTimestamp: string;
}

export interface ApiErrorLogData {
  alarmName: string
  functionName: string;
  state: string;
}

export interface ApiErrorLogDataResponse {
  status: string;
  data: ApiErrorLogData[];
  nextToken: string;
}


export interface ApiLambdaErrorLog {
  timestamp: string;
  message: string;
  logStreamName: string;
}

export interface ApiLambdaErrorLogResponse {
  status: string;
  data: ApiLambdaErrorLog[];
  nextToken: string;
}


export const getDefaultRegion = (): Region => Regions.Canada;
export const getAllRegions = (): Region[] => Object.values(Regions);

export default config;

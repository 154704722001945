import React, { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import { AlertColor, Box } from "@mui/material";
import { Form } from "react-bootstrap";
import config, { getConfigForRegion, Region, Regions, ApiSystemData } from "../../config";
import axios from "axios";
import Utils from "../../utils";
import CustomSnackbar from "../../components/CustomSnackbar";
import CustomButton from "../../components/CustomButton";
import BackButton from "../../components/BackButton";

const { ID_CREATE_NEW } = config?.app;

const HEADER_NAME = "";

const System = () => {
  const { systemId } = useParams();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isAuthenticating,
    refreshSystems,
    systems,
    companies,
    headersWithAuth,
  } = React.useContext(FrontEndContext);

  const isCreatingNew = systemId === ID_CREATE_NEW;

  const [initialSystemData, setInitialSystemData] = useState<ApiSystemData | undefined>(undefined);
  const [updatedSystemData, setUpdatedSystemData] = useState<ApiSystemData | undefined>(undefined);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success");
  const [isLoading, setIsLoading] = useState(false);
  const [isNameFieldFocused, setIsNameFieldFocused] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<Region>(Regions.Canada);
  const systemNameRef = React.useRef<HTMLInputElement>(null);

  // Filter companies based on selected region
  const regionCompanies = useMemo(() => 
    companies.filter(company => company.region === selectedRegion.displayName),
    [companies, selectedRegion]
  );

  const companiesOptions = Utils.getCompaniesOptions(
    regionCompanies,
    updatedSystemData?.companyId
  );

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const isSystemChanged = (): boolean => {
    const noChange =
      !!initialSystemData &&
      !!updatedSystemData &&
      Object.is(initialSystemData, updatedSystemData);
    return !noChange;
  };

  const validateSystemId = (id?: string) => id && id.length === 32;

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (systems) {
      const systemFromId = systems.find(
        (system) => system.id.toString() === systemId
      );
      setUpdatedSystemData(systemFromId);
      setInitialSystemData(systemFromId);
      
      // Set initial region based on system's company region
      if (systemFromId && companies) {
        const systemCompany = companies.find(company => company.id === systemFromId.companyId);
        if (systemCompany) {
          const region = Object.values(Regions).find(r => r.displayName === systemCompany.region);
          if (region) {
            setSelectedRegion(region);
          }
        }
      }
    }
  }, [systemId, systems, companies]);

  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRegion = Object.values(Regions).find(r => r.id === e.target.value);
    if (newRegion) {
      setSelectedRegion(newRegion);
      // Reset company selection with -1 instead of undefined
      setUpdatedSystemData(prev => ({
        ...prev,
        companyId: -1  // Use -1 as a sentinel value for "no selection"
      } as ApiSystemData));
    }
  };

  const handleUpdate = (
  e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  prop: string
) => {
  const newSystemData = { ...(updatedSystemData as ApiSystemData) };
  if (prop === "companyId") {
    newSystemData.companyId = parseInt(e.target.value);
  } else if (prop === "id") {
    console.log("Received value:", e.target.value);
    newSystemData.id = e.target.value;
    console.log("Setting value:", newSystemData.id);
  } else {
    (newSystemData as any)[prop] = e.target.value;
  }
  setUpdatedSystemData(newSystemData);
};

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const sendRequest = async () => {
      const systems = [
        {
          id: updatedSystemData?.id,
          companyId: updatedSystemData?.companyId,
          name: systemNameRef.current?.value,
        },
      ];

      let userAction = isCreatingNew ? "Created" : "Updated";
      const currentConfig = getConfigForRegion(selectedRegion);

      try {
        setIsLoading(true);
        let createSystemResponse;
        setOpenSnackbar(true);
        
        if (!isCreatingNew) {
          setSnackbarMessage("System Update in Progress");
          setSnackbarSeverity("success");
          createSystemResponse = await axios.post(
            currentConfig.api.UPDATE_SYSTEMS,
            {
              id: updatedSystemData?.id,
              companyId: updatedSystemData?.companyId,
            },
            headersWithAuth
          );
        } else {
          setSnackbarMessage("System Provisioning in process");
          setSnackbarSeverity("success");
          createSystemResponse = await axios.post(
            currentConfig.api.CREATE_SYSTEMS,
            { systems },
            headersWithAuth
          );
        }
        
        const errorCode = createSystemResponse?.data[0]?.errorCode;
        if (errorCode) {
          setSnackbarMessage(createSystemResponse?.data[0]?.message);
          setSnackbarSeverity("error");
        } else if (createSystemResponse?.status >= 400) {
          throw new Error(`${createSystemResponse?.status}`);
        } else if (isCreatingNew) {
          const currentSystemId = systems[0].id;
          const timeToCallApi = 2000;
          const numMinuteForTimeout = 5;
          const maxNumCall = (numMinuteForTimeout * 60 * 1000) / timeToCallApi;

          for (let i = 0; i <= maxNumCall; i++) {
            try {
              const systemsResponse = await axios.post<ApiSystemData[]>(
                currentConfig.api.SYSTEMS,
                {},
                headersWithAuth
              );
              
              const isProvisioned = systemsResponse.data.some((sys) => {
                if (currentSystemId === sys.id && sys.status === "provisioned") {
                  setSnackbarMessage(`System ${userAction} successfully!`);
                  setSnackbarSeverity("success");
                  return true;
                }
                return false;
              });

              if (isProvisioned) break;

              await new Promise((resolve) => setTimeout(resolve, timeToCallApi));
            } catch (pollingError) {
              console.error("Error polling systems:", pollingError);
            }
          }
        } else {
          setInitialSystemData(updatedSystemData);
          setSnackbarMessage(`System ${userAction} successfully!`);
          setSnackbarSeverity("success");
        }
      } catch (error) {
        setSnackbarMessage(`${userAction} System ${currentConfig.api.API_FAIL_ERROR}${error}`);
        setSnackbarSeverity("error");
      } finally {
        setIsLoading(false);
        setOpenSnackbar(true);
      }
    };

    await sendRequest();
    refreshSystems();
  };

  const pageTitle = isCreatingNew ? `New System` : `System Id: ${systemId}`;
  const name = updatedSystemData?.name || "";
  const id = updatedSystemData?.id || "";

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <Box className="home-container py-3">
        <Box sx={{ padding: 2 }}>
          <h1>{pageTitle}</h1>
          <hr />
          <Form onSubmit={handleSubmit}>
            {isCreatingNew && (
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  type="text"
                  value={id}
                  maxLength={32}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length < 32){
                      
                    }
                    handleUpdate(e, "id");
                  }}
                />
                <small
                  style={{
                    fontStyle: "italic",
                    color: "grey",
                    fontSize: "0.8em",
                  }}
                >
                  System Id should be of 32 characters it cannot be blank. (current length: {id.length})
                </small>
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                disabled={!isCreatingNew || name.length === 0}
                ref={systemNameRef}
                type="text"
                value={name || "NEW SYSTEM"}
                onChange={(e) => handleUpdate(e, "name")}
                readOnly={isCreatingNew}
                className="readonly"
                onFocus={() => setIsNameFieldFocused(true)}
                onBlur={() => setIsNameFieldFocused(false)}
              />
              {!isCreatingNew && isNameFieldFocused && (
                <div className="text-danger">This field is not editable</div>
              )}
            </Form.Group>

            {isCreatingNew && (
              <Form.Group className="mb-3" controlId="formRegion">
                <Form.Label>Region</Form.Label>
                <Form.Select
                  value={selectedRegion.id}
                  onChange={handleRegionChange}
                >
                  {Object.values(Regions).map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.displayName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Label>Company *</Form.Label>
              <Form.Select
                required
                disabled={!isCreatingNew || regionCompanies.length === 0}
                aria-label="Company selection list"
                value={updatedSystemData?.companyId?.toString() || ""}
                onChange={(e) => handleUpdate(e, "companyId")}
              >
                <option value="">Select a company</option>
                {companiesOptions}
              </Form.Select>
              {isCreatingNew && regionCompanies.length === 0 && (
                <small className="text-danger">
                  No companies available in the selected region
                </small>
              )}
            </Form.Group>

            <Box display="flex" gap={2}>
              <BackButton>Back</BackButton>
              <CustomButton
                type="submit"
                disabled={!isSystemChanged() || isLoading || !validateSystemId(updatedSystemData?.id) || !updatedSystemData?.companyId}
                isLoading={isLoading}
                text="Submit"
              />
            </Box>
          </Form>
        </Box>
      </Box>

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
        autoHideDuration={isCreatingNew ? 30000 : 6000}
      />
    </Page>
  );
};

export default System;
import { useNavigate } from "react-router-dom";
import MUIDataTable, {
  FilterType,
  Responsive,
  SelectableRows,
} from "mui-datatables";
import LockResetIcon from "@mui/icons-material/LockReset";
import { AlertColor, Box, createTheme, ThemeProvider } from "@mui/material";
import "./index.css";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import config, { ApiUserData, getConfigForRegion, Regions } from "../../config";
import ButtonWithDialog from "../DialogButton";
import { SyntheticEvent, useEffect, useState } from "react";
import React from "react";
import FrontEndContext from "../../context/FrontEndContext";
import CustomSnackbar from "../CustomSnackbar";
import axios from "axios";
import CustomButton from "../CustomButton";
import { ResetPasswordModal } from "../ResetPasswordModal";

const { ID_CREATE_NEW } = config.app;

interface UsersListProps {
  usersValid: boolean;
  users?: ApiUserData[];
}

export default function UsersList(props: UsersListProps) {
  const { usersValid, users } = props;
  const navigate = useNavigate();
  const data = (usersValid && users) || [];
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success");
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedUserRegion, setSelectedUserRegion] = useState<string>("");

  const { isAuthenticated, isAuthenticating, refreshUsers, headersWithAuth } =
    React.useContext(FrontEndContext);

  function formatLastLogin(lastLoginTime: string): string {
    if (!lastLoginTime) return "-";
    const date = new Date(lastLoginTime);
    return date.toLocaleString();
  }

  const handleResetPasswordClick = (userId: number, region: string) => {
    setSelectedUserId(userId);
    setSelectedUserRegion(region);
    setIsResetPasswordModalOpen(true);
  };

  const handlePasswordSubmit = async (password: string) => {
    try {
      setIsResetPasswordModalOpen(true);
      setIsLoading(true);
      setOpenSnackbar(true);
      setSnackbarMessage("Updating password ...");
      setSnackbarSeverity("success");

      if (!selectedUserId) throw new Error("User not selected!");
      const user = users?.find((u) => u.id === selectedUserId);
      if (!user?.email) throw new Error("User email not found!");

      const selectedRegion = Object.values(Regions).find(
        (r) => r.displayName === selectedUserRegion
      );
      if (!selectedRegion) throw new Error("Invalid region");

      const regionConfig = getConfigForRegion(selectedRegion);
      
      const requestObject = {
        email: user.email,
        password: password,
      };

      const response = await axios.post(
        regionConfig.api.UPDATE_USER_PASSWORD,
        requestObject,
        headersWithAuth
      );

      if (response.data) {
        setSnackbarMessage("Password updated successfully!");
        setSnackbarSeverity("success");
      } else {
        throw new Error("Failed to update password!");
      }
    } catch (error: any) {
      console.error("Error updating password:", error);
      setSnackbarMessage(`Error updating password: ${error.message}`);
      setSnackbarSeverity("error");
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
      setIsResetPasswordModalOpen(false);
    }
  };

  const handleDelete = async (email: string, region: string) => {
    try {
      setIsLoading(true);
      setOpenSnackbar(true);
      setSnackbarMessage(`Started Deleting the user: ${email} ...`);
      setSnackbarSeverity("success");

      const selectedRegion = Object.values(Regions).find(
        (r) => r.displayName === region
      );
      if (!selectedRegion) throw new Error("Invalid region");

      const regionConfig = getConfigForRegion(selectedRegion);

      const response = await axios.post(
        regionConfig.api.DELETE_USER,
        { email: email },
        headersWithAuth
      );

      if (response.status === 200) {
        setSnackbarMessage("User deleted successfully!");
        setSnackbarSeverity("success");
        refreshUsers();
      } else {
        console.log(`Deleted ${regionConfig.api.API_FAIL_ERROR}${response.status}`);
        setSnackbarMessage("Failed to delete User.");
        setSnackbarSeverity("error");
      }
    } catch (error: any) {
      setSnackbarMessage(`Error: ${error.message || "Failed to delete user"}`);
      setSnackbarSeverity("error");
    } finally {
      setIsLoading(false);
      setOpenSnackbar(true);
    }
  };

  const dataWithLastLogin = data.map((user) => ({
    ...user,
    lastLoginTs: formatLastLogin(user.lastLoginTs),
  }));

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const options = {
    setCellProps: () => ({
      align: "left",
    }),
  };

  const resetPasswordButton = {
    filter: false,
    viewColumns: false,
    customBodyRender: (userId: number, tableMeta: { rowData: any[] }) => {
      // Get region from rowData - index 5 matches the "Region" column position
      const region = tableMeta.rowData[5];
      return (
        <CustomButton
          variant="contained"
          onClick={() => handleResetPasswordClick(userId, region)}
          icon={LockResetIcon}
          iconColor="#1b76d2"
        />
      );
    },
  };

  const buttonViewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <CustomButton
        variant="contained"
        onClick={() => navigate(`/user/${value}`)}
        icon={EditRoundedIcon}
        iconColor="#1b76d2"
      />
    ),
  };

  const deleteButton = {
    filter: false,
    viewColumns: false,
    customBodyRender: (userId: number, tableMeta: { rowData: any[] }) => {
      // Get region from rowData - index 5 matches the "Region" column position
      const region = tableMeta.rowData[5];
      const userToDelete = allUsers.find((user) => user.id === userId);
      
      if (!userToDelete) {
        return null;
      }

      return (
        <ButtonWithDialog
          onDelete={() => handleDelete(userToDelete.email, region)}
          typeOfOperation="Delete"
        />
      );
    },
  };

  const allUsers = users || [];

  const newUserTitle = (
    <CustomButton
      data-cy="createUser"
      variant="primary"
      onClick={() => navigate(`/user/${ID_CREATE_NEW}`)}
      text="Create User"
    ></CustomButton>
  );

  const COLUMNS = [
    { label: " ", name: "id", options: buttonViewOptions },
    { label: " ", name: "id", options: resetPasswordButton },
    { label: " ", name: "id", options: deleteButton },
    { label: "First Name", name: "firstName", options },
    { label: "Last Name", name: "lastName", options },
    { label: "Region", name: "region", options },
    { label: "Email", name: "email", options },
    { label: "Company", name: "companyName", options },
    { label: "Last Login Time", name: "lastLoginTs", options },
  ];

  const OPTIONS = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "vertical" as Responsive,
    search: true,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: "users",
    textLabels: {
      body: {
        noMatch: usersValid ? "No users available." : "Loading ...",
      },
    },
  };

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "left",
            },
          },
        },
      },
    });

  return (
    <Box className="users-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={newUserTitle}
          data={dataWithLastLogin}
          columns={COLUMNS}
          options={OPTIONS}
        />
      </ThemeProvider>

      <ResetPasswordModal
        open={isResetPasswordModalOpen}
        onClose={() => setIsResetPasswordModalOpen(false)}
        onSubmit={handlePasswordSubmit}
      />

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
}